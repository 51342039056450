import { Component, Vue, Watch } from "vue-property-decorator";

import { api } from "@/services/api";
import { Alert } from "@/services/notification";

import { LicitacaoService } from "@/services/licitacaoService";

//components
import PortalAdminContent from "@/components/portalAdminContent2/PortalAdminContent2.vue";

//extra plugins
import { maska } from "maska";

import { parseDateEnUs } from "@/utils";

//warppets
import { wIcon, Breadcrumb } from "@/components/wrappers";

@Component({
  components: {
    wIcon,
    PortalAdminContent,
    Breadcrumb,
  },
  directives: { maska },
})
export default class LicitacaoId extends Vue {
  isUpdate = false;

  licitacaoService = new LicitacaoService(this.$store);

  alert = new Alert(this.$store);
  isLoading = false;
  isShowAnexar = false;
  item: any = {
    status: 0,
    fksituacao: "",
    fkmodalidade: "",
    dataabertura: new Date(),
    horaabertura: new Date(),
    datapublicacao: new Date(),
    telefone: "",
  };
  isError = false;
  dropFiles: any = [];
  anexos = [];

  modalidadesSelect = this.licitacaoService.getModalidadesValues();
  situacoesSelect = this.licitacaoService.getSituacoesValues();

  mounted() {
    if (this.$route.params.id != undefined) {
      this.isUpdate = true;
      this.getId(this.$route.params.id);
    } else {
      this.item.telefone = "(27) 3736-1356";
      this.isUpdate = false;
    }
  }

  getId(id: string) {
    this.isLoading = true;
    const success = (response: any) => {
      this.item = this.licitacaoService.processReceiveData(response.data);
      this.isError = false;
      this.getAnexos(id);
    };
    const error = (error: any) => {
      console.log(error);
      this.alert.notification(
        "is-danger",
        error?.response?.data?.errors[0].msg
      );
      // this.isError = true;
    };
    const url = "/licitacao/" + id;
    api
      .get(url)
      .then(success)
      .catch(error)
      .finally(() => {
        this.isLoading = false;
      });
  }

  clickBtnUpdateAnexo(item: any) {
    this.isLoading = true;
    const success = (response: any) => {
      this.alert.success("Alterações salva com Sucesso");
      this.getAnexos(this.item.idLicitacao);
      this.isError = false;
    };
    const error = (error: any) => {
      console.log(error);
      this.getAnexos(this.item.idLicitacao);
      this.alert.notification(
        "is-danger",
        error?.response?.data?.errors[0].msg
      );
      // this.isError = true;
    };
    const data = Object.assign({}, item);
    data.data = parseDateEnUs(item.data);
    const url =
      "/licitacao/" +
      this.item.idLicitacao +
      "/anexos/" +
      data.idLicitacaoanexo;
    delete data.idLicitacaoanexo;
    delete data.arquivo;
    api
      .put(url, data)
      .then(success)
      .catch(error)
      .finally(() => {
        this.isLoading = false;
      });
  }

  getAnexos(id: string) {
    this.isLoading = true;
    const success = (response: any) => {
      const anexos = response.data.list;
      // this.anexos = response.data.list
      this.anexos = this.licitacaoService.processListAnexosReceive(anexos);
      this.isError = false;
    };
    const error = (error: any) => {
      console.log(error);
      this.alert.notification(
        "is-danger",
        error?.response?.data?.errors[0].msg
      );
    };
    const url = "/licitacao/" + id + "/anexos";
    api
      .get(url)
      .then(success)
      .catch(error)
      .finally(() => {
        this.isLoading = false;
      });
  }

  clickEnviarArquivos() {
    const data = this.mountFormData();
    console.log(data);
    this.sendPostAnexo(data);
  }

  mountFormData() {
    const data = new FormData();
    // this.dropFiles.map( (file: File, i: number) => {
    //   data.append('file'+i, file);

    // })
    data.append("file", this.dropFiles);
    data.append("titulo", "Documento");
    data.append("fktipo", "2");
    const dateNow = new Date();
    data.append("data", parseDateEnUs(dateNow));
    data.append("status", "1");
    return data;
  }

  deleteDropFile(index: any) {
    this.dropFiles.splice(index, 1);
  }

  clearListDropFiles() {
    this.dropFiles = [];
  }

  clickDeleteFile(item: any) {
    const onConfirm = () => {
      console.log(item);
      this.sendDeleteFile(item.idLicitacaoanexo);
    };
    this.alert.dialogConfirm(`Deseja deletar o arquivo?`, onConfirm);
  }

  sendDeleteFile(idFile: any) {
    this.isLoading = true;
    const success = (response: any) => {
      console.log(response);
      this.alert.success("Arquivo Deletado com Sucesso");
      this.getAnexos(this.item.idLicitacao);
      this.isError = false;
    };
    const error = (error: any) => {
      console.log(error);
      this.alert.notification(
        "is-danger",
        error?.response?.data?.errors[0].msg
      );
      // this.isError = true;
    };
    const url = "/licitacao/" + this.item.idLicitacao + "/anexos/" + idFile;
    api
      .delete(url)
      .then(success)
      .catch(error)
      .finally(() => {
        this.isLoading = false;
      });
  }

  clickDeleteItem() {
    const onConfirm = () => {
      this.sendDelete();
    };
    this.alert.dialogConfirm("Deseja continuar com esta operação?", onConfirm);
  }

  sendPostAnexo(data: any) {
    this.isLoading = true;
    const success = (response: any) => {
      this.alert.success("Anexo Adicionado com Sucesso");
      this.clearListDropFiles();
      this.getAnexos(this.item.idLicitacao);
      this.isError = false;
    };
    const error = (error: any) => {
      console.log(error);
      this.alert.notification(
        "is-danger",
        error?.response?.data?.errors[0].msg
      );
      // this.isError = true;
    };
    const url = "/licitacao/" + this.item.idLicitacao + "/anexos";
    api
      .post(url, data)
      .then(success)
      .catch(error)
      .finally(() => {
        this.isLoading = false;
      });
  }

  sendDelete() {
    this.isLoading = true;
    const success = (response: any) => {
      console.log(response);
      this.alert.success("Licitação Deletada com Sucesso");
      this.isError = false;
      this.$router.push("/transparencia/licitacoes");
    };
    const error = (error: any) => {
      console.log(error);
      this.alert.notification(
        "is-danger",
        error?.response?.data?.errors[0].msg
      );
      // this.isError = true;
    };
    const url = "/licitacao/" + this.item.idLicitacao;
    api
      .delete(url)
      .then(success)
      .catch(error)
      .finally(() => {
        this.isLoading = false;
      });
  }

  clickBtnSalvar() {
    if (this.isUpdate) {
      this.sendUpdate();
    } else {
      this.sendPost();
    }
  }

  sendPost() {
    this.isLoading = true;
    const success = (response: any) => {
      console.log(response);
      console.log(response);
      this.$router.push(
        "/transparencia/licitacoes/" + response.data.idLicitacao
      );
      this.alert.success("Licitação Criada com Sucesso");
      this.isError = false;
    };
    const error = (error: any) => {
      console.log(error);
      this.alert.notification(
        "is-danger",
        error?.response?.data?.errors[0].msg
      );
      // this.isError = true;
    };
    console.log(this.item.dataabertura);
    let data = Object.assign({}, this.item);
    for (const key in data) {
      if (data[key] == "") {
        delete data[key];
      }
    }
    data.fksituacao = String(data.fksituacao);
    data.fkmodalidade = String(data.fkmodalidade);
    data = this.licitacaoService.processToSendData(data);
    const url = "/licitacao";
    api
      .post(url, data)
      .then(success)
      .catch(error)
      .finally(() => {
        this.isLoading = false;
      });
  }

  @Watch("$route", {
    immediate: false,
    deep: true,
  })
  changeRoute() {
    if (this.$route.params.id != undefined) {
      this.isUpdate = true;
      this.getId(this.$route.params.id);
    } else {
      this.isUpdate = false;
    }
    window.scrollTo(0, 0);
  }

  sendUpdate() {
    this.isLoading = true;
    const success = (response: any) => {
      console.log(response);
      this.alert.success("Licitação Salva com Sucesso");
      this.isError = false;
    };
    const error = (error: any) => {
      console.log(error);
      this.alert.notification(
        "is-danger",
        error?.response?.data?.errors[0].msg
      );
      // this.isError = true;
    };
    let data = Object.assign({}, this.item);
    delete data.idLicitacao;
    data.fksituacao = "" + data.fksituacao;
    data = this.licitacaoService.processToSendData(data);
    for (const key in data) {
      if (data[key] == "" && key != "valor_contrato") {
        delete data[key];
      }
    }
    const url = "/licitacao/" + this.item.idLicitacao;
    api
      .put(url, data)
      .then(success)
      .catch(error)
      .finally(() => {
        this.isLoading = false;
      });
  }

  getFileName(file: any) {
    let arr = file.arquivo;
    arr = arr.split("/");
    return arr[arr.length - 1];
  }

  getFileLocation(file: any) {
    let arr = file.arquivo;
    arr = arr.split("/");
    // console.log(arr);
    if (arr[2] == "apitransparencia2.laranjadaterra.es.gov.br") {
      return "http://apitransparencia2.laranjadaterra.es.gov.br" + file.arquivo;
    }
    if (arr[1] == "uploads" && arr[2] == "licitacao") {
      return "https://antigo.laranjadaterra.es.gov.br" + file.arquivo;
    }
    if (arr[1] == "novosUploads" && arr[2] == "licitacoes") {
      return "https://antigo.laranjadaterra.es.gov.br" + file.arquivo;
    }
    return file.arquivo;
  }
}
